import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import offcanvas from './components/offcanvas';
// import timer from './components/timer';

outline();

const showMenuBtn = $('.show-menu');
const navigation = offcanvas($('.offcanvas-nav'));
showMenuBtn.addEventListener('click', navigation.open, { passive: true });

export default function () {
  // timer();
  // if (document.querySelector('.clock') !== null) {
  //   import('./components/timer.js')
  //     .then(timer => timer.default())
  //     .catch((err) => {
  //       console.warn('Chunk timer.js loading failed', err);
  //     });
  // }

  if ($('.menu') !== null) {
    import('./components/menucart/')
      .then(menucart => menucart.default())
      .catch((err) => {
        console.warn('Chunk menucart.js loading failed', err);
      });
  }

  if ($('.notice-wrapper') !== null) {
    import('./components/notices')
      .then(notices => notices.default())
      .catch((err) => {
        console.warn('Chunk notices.js loading failed', err);
      });
  }

  if ($('.nav-tabs') !== null || $('[data-toggle="collapse"]') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
