import focusTrap from 'focus-trap';

const isIOS = document.documentElement.classList.contains('ios');
let bodyScrollLock;

if (isIOS === true) {
  import('body-scroll-lock')
    .then((resp) => {
      bodyScrollLock = resp;
    });
} else {
  bodyScrollLock = {};
}

export default (el, direction = 'right') => {
  if (el == null) {
    console.warn('No valid element set for offcanvas');
  }

  const offcanvasWrapper = el;
  const offcanvasInner = offcanvasWrapper.querySelector('.offcanvas-inner');
  const closeOffcanvas = offcanvasWrapper.querySelector('.close-offcanvas');
  // const backdrop = offcanvasWrapper.querySelector('.backdrop');
  const trap = focusTrap(offcanvasWrapper, {
    initialFocus: closeOffcanvas,
  });

  const tl = new TimelineLite({
    paused: true,
    onComplete() {
      offcanvasWrapper.setAttribute('aria-hidden', false);

      // disable page scroll
      if (isIOS === true) {
        bodyScrollLock.enableBodyScroll && bodyScrollLock.disableBodyScroll(offcanvasWrapper); // eslint-disable-line
      } else {
        document.documentElement.classList.add('noscroll');
        trap.activate();
      }
    },
    onReverseComplete() {
      offcanvasWrapper.setAttribute('aria-hidden', true);

      // enable page scroll
      if (isIOS === true) {
        bodyScrollLock.enableBodyScroll && bodyScrollLock.enableBodyScroll(offcanvasWrapper); // eslint-disable-line
      } else {
        document.documentElement.classList.remove('noscroll');
        trap.deactivate();
      }
    },
  });

  tl
    .to(offcanvasWrapper, 0.225, {
      pointerEvents: 'auto',
      visibility: 'inherit',
    })
    // .to(backdrop, 0.225, {
    //   opacity: 0.5,
    //   ease: Sine.easeOut,
    // }, '-=0.225')
    .to(offcanvasInner, 0.225, {
      xPercent: 0,
      autoAlpha: 1,
      pointerEvents: 'auto',
      ease: Sine.easeOut,
      startAt: {
        xPercent: direction === 'left' ? -100 : 100,
      },
    }, '-=0.125');

  // close nav
  closeOffcanvas.addEventListener('click', () => {
    tl.reverse();
  }, { passive: true });

  offcanvasWrapper.addEventListener('click', (e) => {
    if (e.target === offcanvasWrapper) {
      tl.reverse();
    }
  }, { passive: true });

  window.addEventListener('keyup', (e) => {
    if (e.keyCode === 27) {
      if (tl.progress() > 0) {
        tl.reverse();
      }
    }
  });

  return {
    open() {
      tl.play();
    },
  };
};
